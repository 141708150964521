import React, { Suspense, lazy } from 'react'
import { HashRouter, Router, Redirect, Route, Switch } from 'react-router-dom'
import { ResetCSS } from '@pancakeswap/uikit'
import BigNumber from 'bignumber.js'
import useEagerConnect from 'hooks/useEagerConnect'
import { usePollCoreFarmData, useFetchProfile, usePollBlockNumber } from 'state/hooks'
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
// import Suspense from './components/Suspense'
import ToastListener from './components/ToastListener'
import PageLoader from './components/PageLoader'
/* import EasterEgg from './components/EasterEgg'
import Pools from './views/Pools'
import history from './routerHistory' */
import Predictions from './views/Predictions'
import PredictionsWithToken from './views/PredictionsWithToken'
import BtcPredictions from './views/BtcPredictions'
import NotFound from './views/NotFound'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page

// const Home = lazy(() => import('./views/Home'))
// const Predictions = lazy(() => import('./views/Predictions'))
// const PredictionsWithToken = lazy(() => import('./views/PredictionsWithToken'))
/* const Farms = lazy(() => import('./views/Farms'))
const Lottery = lazy(() => import('./views/Lottery'))
const Ifos = lazy(() => import('./views/Ifos'))
const Collectibles = lazy(() => import('./views/Collectibles'))
const Teams = lazy(() => import('./views/Teams'))
const Team = lazy(() => import('./views/Teams/Team'))
const Profile = lazy(() => import('./views/Profile'))
const TradingCompetition = lazy(() => import('./views/TradingCompetition'))
const NotFound = lazy(() => import('./views/NotFound')) */



// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  usePollBlockNumber()
  useEagerConnect()
  useFetchProfile()
  usePollCoreFarmData()

  return (
    <Suspense fallback={<PageLoader />}>
      <HashRouter>
        <ResetCSS />
          <GlobalStyle />
            <Menu>
                <Switch>
                  <Route exact strict path="/" component={BtcPredictions}>
                    <Redirect to="/bnb" />
                  </Route>
                  <Route exact strict path="/bnb" component={Predictions} />
                  <Route exact path="/icebrk" component={PredictionsWithToken} />
                  <Route exact path="/btc" component={BtcPredictions} />
                  <Route component={NotFound} />
{/*                   <Route path="/farms">
                    <Farms />
                  </Route>
                  <Route path="/pools">
                    <Pools />
                  </Route>
                  <Route path="/lottery">
                    <Lottery />
                  </Route>
                  <Route path="/ifo">
                    <Ifos />
                  </Route>
                  <Route path="/collectibles">
                    <Collectibles />
                  </Route>
                  <Route exact path="/teams">
                    <Teams />
                  </Route>
                  <Route path="/teams/:id">
                    <Team />
                  </Route>
                  <Route path="/profile">
                    <Profile />
                  </Route>
                  <Route path="/competition">
                    <TradingCompetition />
                  </Route>
                  <Route path="/staking">
                    <Redirect to="/pools" />
                  </Route>
                  <Route path="/syrup">
                    <Redirect to="/pools" />
                  </Route>
                  <Route path="/nft">
                    <Redirect to="/?inputCurrency=BNB&outputCurrency=0x82b4b883c813dF83623Dc7894c1895305680fec2" />
                  </Route> */}
                  {/* 404 */}
                </Switch>
            </Menu>
        <ToastListener />
      </HashRouter>
    </Suspense>
  )
}

export default React.memo(App)
