import { MenuEntry } from '@pancakeswap/uikit'
import { getCakeAddress } from 'utils/addressHelpers'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://icebreak-r.com/',
  },
  {
    label: 'Swap',
    icon: 'TradeIcon',
    href: 'https://swap.icebreak-r.com/#/swap?inputCurrency=BNB&outputCurrency=0x82b4b883c813df83623dc7894c1895305680fec2',
  },
  {
    label: 'Liquidity',
    icon: 'TradeIcon',
    href: 'https://swap.icebreak-r.com/#/add/BNB/0x82b4b883c813df83623dc7894c1895305680fec2',
  },
  {
    label: 'Prediction Markets',
    icon: 'PredictionsIcon',
    items: [
      {
        label: "BNB Prediction (Wagering BNB)",
        href: '/bnb',
      },
      {
        label: "BNB Prediction (Wagering Icebrk)",
        href: '/icebrk',
      },
      {
        label: "BTC Prediction (Wagering BNB)",
        href: '/btc',
      },
    ],
  },
  {
    label: 'Info',
    icon: 'InfoIcon',
    items: [
      {
        label: 'IceBreak-R Chart on DexTools',
        href: 'https://www.dextools.io/app/pancakeswap/pair-explorer/'.concat(getCakeAddress()),
      },
      {
        label: 'IceBreak-R on PancakeSwap',
        href: 'https://pancakeswap.info/token/'.concat(getCakeAddress()),
      },
    ],
  }
]

export default config
